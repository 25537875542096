/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Form, Input, Radio } from 'antd'
import { industryOptions, softwareOptions, problemOptions } from './const'
import { CheckBox, Group } from './checkBox'
import { Button } from '@ys/components'
import { Http } from '@/utils'
import { currentUser } from '@/domain'
import { CompanyApplySuccess } from './succeed'

const StyledLayout = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 40px 56px;
  font-family: PingFangSC-Medium;
  > .title {
    font-size: 24px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 8px;
    > .weight {
      font-weight: bold;
    }
  }

  > .tip {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #666666;
    font-weight: 400;
  }
  //更改antd样式
  .ant-form-item-label {
    text-align: left;
    margin: 8px 0;
  }
  .ant-form-item-label > label {
    font-size: 16px;
    color: #666666;
  }
  .ant-radio-wrapper {
    width: 115px;
    padding: 0 0 3px 0;
  }
  //
  .ant-form-item {
    margin-bottom: 0;
  }
  > .content {
    width: 494px;
    height: 424px;
    overflow: auto;
    padding: 20px 0;
    &::-webkit-scrollbar  {
      display: none;
    }
    .required {
      color: red;
    }
  }
  > .footer {
    padding: 24px 0 10px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
const PageLayout = styled.div`
  display: flex;
  flex-direction: row;
  .row {
    display: flex;
    width: 100%;
    padding: 13px 0;
    > .box {
      margin: 0 10px;
      width: 104px;
      height: 22px;
      border: 1px solid #666666;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
    }
    > .box:hover {
      border: 1px solid #005dfc;
    }
    > .checked {
      background: #3182ff;
      border-radius: 2px;
      color: #ffffff;
      border: none;
      border: 1px solid transparent;
    }
  }
`

interface Props {
  onOk: () => void
  formData: any
}

const { TextArea } = Input

export const ApplyModal = observer(function applyModal({
  onOk,
  formData
}: Props) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  async function onFinish(value) {
    try {
      setLoading(true)
      await Http.post('applyCompany/applyForm', {
        ...value,
        apply_reason: value.apply_reason.concat(value.problems).filter(d => d),
        phone: currentUser.phone
      })
      onOk()
      await CompanyApplySuccess()
    } finally {
      setLoading(false)
    }
  }
  return (
    <StyledLayout>
      <div className='title'>
        <span className='normal'>欢迎升级 </span>
        <span className='weight'>远算开物企业版</span>
      </div>
      <div className='tip'>请完善以下几项信息，让我们更好的为您服务！</div>
      <div className='content'>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            ...formData,
            apply_reason: formData.apply_reason,
            engineer_num:
              formData.engineer_num > 0 ? formData.engineer_num : undefined,
            software_list:
              formData.software_list.length > 0
                ? formData.software_list
                : undefined
          }}>
          <Form.Item required label='企业名称：'></Form.Item>
          <Form.Item
            name='company_name'
            rules={[
              {
                required: true,
                message: '请输入您的企业名称'
              },
              {
                pattern: /^[a-zA-Z0-9\u4e00-\u9fa5]{2,64}$/,
                message: '请输入2~64个字符，包含中英文，不包含特殊符号'
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item label='姓名：' required></Form.Item>
          <Form.Item
            name='user_name'
            rules={[
              {
                required: true,
                message: '请输入您的姓名'
              },
              {
                pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,64}$/,
                message: '请输入2~64个字符，包含中英文，不包含特殊符号'
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item required label='您的行业：'></Form.Item>
          <Form.Item
            name='industry'
            rules={[
              {
                required: true,
                message: '请选择您的行业'
              }
            ]}>
            <Radio.Group options={industryOptions} />
          </Form.Item>
          <Form.Item required label='企业工程师数量：'></Form.Item>
          <Form.Item
            name='engineer_num'
            rules={[
              {
                required: true,
                message: '请选择企业工程师数量'
              }
            ]}>
            <Radio.Group>
              <Radio value={5}>1~5人</Radio>
              <Radio value={10}>6~10人</Radio>
              <Radio value={20}>11~20人</Radio>
              <Radio value={25}>21人以上</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item required label='工程师平常使用的软件(多选)：'></Form.Item>
          <Form.Item
            style={{ height: '220px' }}
            name='software_list'
            rules={[
              {
                required: true,
                message: '请选择工程师平常使用的软件(多选)'
              }
            ]}>
            <CheckBox dataOptions={softwareOptions} />
          </Form.Item>
          <Form.Item
            style={{ fontSize: '16px', color: '#666666' }}
            label='希望远算提供的能力或解决的问题：'></Form.Item>
          <PageLayout>
            <Form.Item name='apply_reason' label='常见问题：'>
              <Group dataOptions={problemOptions} />
            </Form.Item>
          </PageLayout>
          <Form.Item name='problems'>
            <TextArea
              placeholder='请输入0~512个字符'
              autoSize={false}
              style={{ height: '96px' }}
              maxLength={512}
            />
          </Form.Item>
        </Form>
      </div>
      <div className='footer'>
        <Button loading={loading} onClick={() => form.submit()} type='primary'>
          {formData.company_name ? '重新提交' : '提交申请'}
        </Button>
      </div>
    </StyledLayout>
  )
})
