/* Copyright (C) 2016-present, Yuansuan.cn */

import { Http } from '@/utils'

export type ListFilterParams = Partial<{
  job_id: string
  user_id: string
  company_id: string
  status: number
  start_time: string
  end_time: string
  page_index: number
  page_size: number
}>

export type CreateTroubleShootParams = Partial<{
  job_id: string
  job_name: string
  submitter_user_id: string
  submitter_user_name: string
  product_id: string
  logs: string
  company_id: string
  submitter_user_phone: string
  msg: string
  url: string
}>

export type CreateTroubleShootRecordsParams = Partial<{
  trouble_shoot_id: number
  ys_staff_user_id: string
  is_submitter: boolean
  ys_staff_user_name: string
  msg: string
}>
export type UpdateTroubleShootResolvedParams = Partial<{
  id: number
  resolved: boolean
  is_submitter: boolean
}>

export const TroubleShootWorkOrderListService = {
  async getTroubleShootWorkOrderList(params: ListFilterParams) {
    const {
      data: { list, page_ctx }
    } = await Http.get('/workorder', {
      params: {
        ...params
      }
    })

    return { list, page_ctx }
  },

  /**
   * 提出问题
   * @param params
   * @returns
   */
  async createTroubleShoot(params: CreateTroubleShootParams) {
    const {
      data: { trouble_shoot }
    } = await Http.post('/workorder/troubleshoot', {
      ...params
    })

    return { trouble_shoot }
  },

  async getTroubleShoot({ id }) {
    const {
      data: { result }
    } = await Http.get('/workorder/get/troubleshoot', {
      params: {
        id
      }
    })
    return { result }
  },

  async createTroubleShootRecord(params: CreateTroubleShootRecordsParams) {
    const {
      data: { record }
    } = await Http.post('/workorder/create/records', {
      ...params
    })

    return { record }
  },

  async getTroubleShootCount({ user_id }) {
    const { data } = await Http.get('/workorder/troubleShootCount', {
      params: {
        user_id
      }
    })
    return data
  },

  async updateTroubleShootResolved(params: UpdateTroubleShootResolvedParams) {
    const { data } = await Http.post('/workorder/update/troubleShootResolve', {
      ...params
    })
    return data
  }
}
