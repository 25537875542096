/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import io from 'socket.io-client'
import { notification } from 'antd'
import { notification as ysNotification } from '@/components'
import { Modal } from '@ys/components'
import { lastInvitations, lastMessages, visualTaskList } from '@/domain'
import { history } from '@/utils'
import { apply as applyCloudShell } from '@/components/HeaderToolbar/ShellEntry/utils'

export const socket = io({
  path: '/api/ws',
  transports: ['websocket']
})

// reconnect when socket is error
socket.on('disconnect', () => {
  socket.connect()
})
socket.on('reconnect_error', () => {
  setTimeout(() => socket.connect(), 3000)
})
socket.on('error', () => {
  setTimeout(() => socket.connect(), 3000)
})
socket.on('connect_error', () => {
  setTimeout(() => socket.connect(), 3000)
})

socket.on('confirm_invitation', msg => {
  const { message } = JSON.parse(msg)
  lastInvitations.fetchLast()
  lastMessages.fetchLast()

  notification.error({
    message: '拒绝邀请',
    description: message,
    onClick: () => history.push('/company/invitations')
  })
})

socket.on('delete_user', async msg => {
  const { message } = JSON.parse(msg)

  await Modal.showConfirm({
    title: '退出企业',
    content: `${message}，请重新加载页面`,
    closable: false,
    CancelButton: null
  })

  location.replace('/')
})

socket.on('company_invitation', msg => {
  const { message } = JSON.parse(msg)

  lastInvitations.fetchLast()
  lastMessages.fetchLast()
  notification.info({
    message: '企业邀请',
    description: message,
    onClick: () => history.push('/messages?tab=invitations')
  })
})

socket.on('job_status', msg => {
  const { message, job_name, job_id } = JSON.parse(msg)

  lastMessages.fetchLast()
  notification.info({
    message: '作业状态',
    description: `${job_name}${message}`,
    onClick: () => history.push(`/job/${job_id}`)
  })
})
socket.on('visual_task_status', msg => {
  visualTaskList.onTaskMessage(msg)
})

enum CloudShellApplyType {
  TYPE_UNKNOWN = 0,
  // new binding
  TYPE_BIND = 1,
  // disable binding
  TYPE_UNBIND = 2,
  // reject binding apply
  TYPE_REJECT = 3
}

socket.on('cloud_shell_apply_result', msg => {
  if (msg === CloudShellApplyType.TYPE_BIND) {
    ysNotification.success({
      message: '申请成功！',
      description: '你所提交的信息已经审核通过，可以直接使用cloud shell。'
    })
  } else if (msg === CloudShellApplyType.TYPE_REJECT) {
    ysNotification.error({
      message: '申请失败！',
      key: 'cloudShellApplyError',
      description: (
        <span>
          你所提交的信息审核未通过。
          <a
            onClick={() => {
              applyCloudShell()
              notification.close('cloudShellApplyError')
            }}>
            重新申请
          </a>
        </span>
      )
    })
  }
})
