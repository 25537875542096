/* Copyright (C) 2016-present, Yuansuan.cn */

import { Http } from '@/utils'
import { GuideType } from '@/utils/Guide/typing'

export const guideServer = {
  finish: (guide: GuideType) =>
    Http.post('/guide/read', {
      guide_id: guide?.id,
    }),
}
