/* Copyright (C) 2016-present, Yuansuan.cn */

import { Http } from '@/utils'

export const accountServer = {
  get: (account_id?: string) =>
    Http.get('/account', {
      params: {
        account_id
      }
    }),
  getDetailList: (params: {
    start_seconds: number
    end_seconds: number
    page_index: number
    page_size: number
    account_id: string
  }) =>
    Http.get('/account/detail', {
      params
    }),
  getComboList: (product_id: string) =>
    Http.get('/account/combo', { params: { product_id } })
}
