import { observable } from 'mobx'

export class Zone {
  @observable name
  @observable desc

  constructor(props) {
    if (props) {
      Object.assign(this, props)
    }
  }
}