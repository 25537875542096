/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { User } from './User'
import { Env } from './Env'
import { Messages } from './Messages'
import { CompanyList } from './CompanyList'
import { PermList } from './PermList'
import { EtcdFeatures } from './EtcdFeatures'
import { NoticeList } from './NoticeList'
import { SoftwareFreeTrial } from './SoftwareFreeTrial'

export { User } from './User'
export { Company } from '@/domain/CompanyList/Company'
export * from './socket'
export { InvitationList } from './InvitationList'
export { Messages } from './Messages'

import { InvitationList } from './InvitationList'
import ProjectList from './ProjectList'
import { Domain } from './domain'
import { Uploader } from '@ys/components'
import ApplicationList from './Application'
import { SCList } from './SCList'
import Box from './Box'
import CloudAppList from './Visualization/CloudAppList'
import VisualTaskList from './Visualization/VisualTaskList'
import VisualConfig from './Visualization/VisualConfig'
import VisIBVConfig from './Vis/VisIBVConfig'
import { Tasks } from './Tasks'
import { Account } from './Account'
import { WebConfig } from './WebConfig'
import Vis from './Vis'
export { ZoneList } from './Zone'
import { ZoneList } from './Zone'

export const messages = new Messages()
export const currentUser = new User()
export const companyList = new CompanyList()
export const permList = new PermList()
export const noticeList = new NoticeList()
export const etcdFeatures = new EtcdFeatures()
export const env = new Env()
export const uploader = new Uploader()
export const lastMessages = new Messages()
export const lastInvitations = new InvitationList()
export const appList = new ApplicationList()
export const scList = new SCList()
export const projectList = new ProjectList()
export const vis_ibvList = new ZoneList()
export const domainList = new Domain()
export const cloudAppList = new CloudAppList()
export const visualTaskList = new VisualTaskList()
export const visualConfig = new VisualConfig()
export const tasks = new Tasks()
export const box = new Box()
export const account = new Account()
export const webConfig = new WebConfig()
export { default as BoxHttp } from './Box/BoxHttp'
export { NewBoxHttp } from './Box/NewBoxHttp'
export { useResize } from './useResize'
export const softwareFreeTrial = new SoftwareFreeTrial()
export const vis = new Vis()
export const visIBVConfig = new VisIBVConfig()
