/* Copyright (C) 2016-present, Yuansuan.cn */

import { Http } from '@/utils'

export type ListParams = {
  page_index: number
  page_size: number
  user_filters?: string[]
  app_filters?: string[]
  state_filters?: string[]
  core_filters?: string[]
  download_filters?: string[]
  jobset_filters?: string[]
  fuzzy_key?: string
  order_by?: string
  start_seconds?: string
  end_seconds?: string
  job_id?: string
  sc_zone?: string
  sc_filters?: string[]
  sc_id?: string
}

export const jobServer = {
  get(id) {
    return Http.get(`/job/${id}`)
  },
  getStatus(id) {
    return Http.get(`job/status/${id}`)
  },
  list({
    page_index = 1,
    page_size = 10,
    start_seconds,
    end_seconds,
    ...params
  }: ListParams) {
    return Http.get('/job', {
      params: {
        page: {
          index: page_index,
          size: page_size,
        },
        start_seconds: start_seconds || '0',
        end_seconds: end_seconds || '0',
        ...params,
      },
    })
  },
  getJobSetDetail({ id, page_index = 1, page_size = 10 }) {
    return Http.get(`/job/set/${id}`, {
      params: {
        page: {
          index: page_index,
          size: page_size,
        },
      },
    })
  },
  getFilters() {
    return Http.get('/job/filters')
  },
  cancel(ids: string[]) {
    return Http.post('/job/cancel', {
      ids,
    })
  },
  delete(ids: string[]) {
    return Http.post('/job/delete', {
      ids,
    })
  },
  getResidualData(id) {
    return Http.get(`/job/${id}/residual`)
  },
  pauseOrResume(id, bool: Boolean) {
    return Http.put(`/job/${id}/sync_info`, {
      action: bool ? 'resume' : 'pause',
    })
  },
}
