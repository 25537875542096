export const ignoredErrorCodes = [
  14, // failed to connect to all addresses, grpc service is not available
  993003, // get_task_thumbnail_fail
]

export const overrideErrorCodes = new Map([
  [992001, '获取应用失败'],
  [992002, '获取应用租户失败'],
  [992003, '获取应用文件关系失败'],
  [992004, '获取配置失败'],
  [992101, '创建应用失败'],
  [992102, '更新应用失败'],
  [992103, '删除应用失败'],
  [992104, '工作站和应用绑定失败'],
  [992105, '工作站和应用解绑失败'],
  [993001, '获取任务失败'],
  [993002, '关闭任务失败'],
  [993004, '获取工作站失败'],
  [993006, '打开工作站失败'],
  [994001, '获取节点失败'],
  [994002, '关联镜像不存在'],
  [994004, '打开工作站超过规定数量'],
  [994005, '没有可用工作站资源'],
  [994006, '没有可用工作站资源'],
])
