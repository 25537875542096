/* Copyright (C) 2016-present, Yuansuan.cn */

import { Http } from '@/utils'

export const creditServer = {
  get: (params: { page_index: number; page_size: number }) =>
    Http.get('/credit', {
      params,
    }),
}
