/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observable, action, computed, runInAction } from 'mobx'
import { Http } from '@/utils'
import { Timestamp } from '../common'

interface IRequest {
  message_id: string
  type: string
  content: string
  is_read: boolean
  create_at: {
    seconds: number
    nanos: number
  }
}

export interface IMessage extends Omit<IRequest, 'type' | 'create_at'> {
  keyType: string
  create_at: Timestamp
}

export enum MessageType {
  CompanyInvitation,
  ConfirmInvitation,
  CompanyExit,
  JobRun,
  JobComplete,
  JobFail,
  JobCancel,
  Troubleshoot,
  ProjectOverLimit, // 空间限额
  TroubleshootReplied, //工单问题已回复
  TroubleshootClosed //工单问题已关闭
}

export class Message implements IMessage {
  @observable message_id: string
  @observable keyType: string
  @observable content: string
  @observable is_read: boolean
  @observable create_at: Timestamp

  @computed
  get body() {
    return JSON.parse(this.content)
  }

  @computed
  get type() {
    switch (this.keyType) {
      case 'Message_Company': {
        const { type } = this.body
        return {
          company_invitation: 'company_invitation',
          confirm_invitation: 'confirm_invitation',
          delete_user: 'delete_user',
          troubleshoot: 'troubleshoot' // @TODO: troubleshoot 目前没有合适的 key type，暂归类在 Message_Company 下
        }[type]
      }
      case 'Message_ComputeJobEvent': {
        const { event } = this.body
        return {
          on_running: 'job_running',
          on_completed: 'job_completed',
          on_failed: 'job_failed',
          on_cancelled: 'job_cancelled'
        }[event]
      }
      // 新增空间超额提醒
      case 'Message_Project': {
        const { type } = this.body
        return {
          Message_ProjectOverLimit: 'Message_ProjectOverLimit'
        }[type]
      }
      // 新增工单问题已回复提醒
      case 'Message_Troubleshoot': {
        const { type } = this.body
        return {
          Message_TroubleshootReplied: 'Message_TroubleshootReplied',
          Message_TroubleshootClosed: 'Message_TroubleshootClosed'
        }[type]
      }
      default: {
        return null
      }
    }
  }

  @computed
  get info() {
    return (
      {
        company_invitation: {
          title: '企业邀请',
          type: MessageType.CompanyInvitation
        },
        confirm_invitation: {
          title: '确认邀请',
          type: MessageType.ConfirmInvitation
        },
        delete_user: {
          title: '退出企业',
          type: MessageType.CompanyExit
        },
        job_running: {
          title: '作业运行',
          type: MessageType.JobRun
        },
        job_completed: {
          title: '作业完成',
          type: MessageType.JobComplete
        },
        job_failed: {
          title: '作业失败',
          type: MessageType.JobFail
        },
        job_cancelled: {
          title: '作业取消',
          icon: 'meh',
          color: '#fa541c',
          type: MessageType.JobCancel
        },
        troubleshoot: {
          title: '帮助回复',
          type: MessageType.Troubleshoot
        },
        Message_ProjectOverLimit: {
          title: '空间限额提醒',
          type: MessageType.ProjectOverLimit
        },
        Message_TroubleshootReplied: {
          title: '帮助回复',
          type: MessageType.TroubleshootReplied
        },
        Message_TroubleshootClosed: {
          title: '工单进度提醒',
          type: MessageType.TroubleshootClosed
        }
      }[this.type] || { title: '消息', icon: 'message' }
    )
  }

  @computed
  get title() {
    return this.info.title
  }

  @computed
  get message() {
    if (this.body.project_name) {
      return this.body.project_name + this.body.message
    } else {
      return this.body.message || ''
    }
  }

  constructor(props?: IRequest) {
    if (props) {
      this.init(props)
    }
  }

  @action
  init(props: IRequest) {
    const { type, ...finalProps } = props
    Object.assign(this, finalProps)
    this.keyType = type
    this.create_at = new Timestamp(props.create_at)
  }

  read = async () => {
    await Http.put('/message/read', {
      message_id: [this.message_id]
    })

    runInAction(() => {
      this.is_read = true
    })
  }
}
