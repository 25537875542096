/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observable, action, runInAction } from 'mobx'
import { Timestamp } from '@/domain/common'
import { accountServer } from '@/server'
import { env } from '@/domain'
import { Http } from '@/utils'

export class BaseAccount {
  @observable id: string
  @observable customer_id: string
  @observable real_customer_id: string
  @observable name: string
  @observable currency: string
  @observable account_balance: number = 0
  @observable freezed_amount: number = 0
  @observable normal_balance: number = 0
  @observable award_balance: number = 0
  @observable withdraw_enabled: boolean
  @observable credit_quota: number = 0
  @observable status: number
  @observable create_time: Timestamp
  @observable update_time: Timestamp
  @observable account_balance_contain_freezed: number = 0
  @observable free_trail_balance: number = 0
  @observable is_freeze: boolean
}

type AccountRequest = Omit<BaseAccount, 'create_time' | 'update_time'> & {
  create_time: {
    seconds: number
    nanos: number
  }
  update_time: {
    seconds: number
    nanos: number
  }
}

export class Account extends BaseAccount {
  constructor(props?: Partial<AccountRequest>) {
    super()

    if (props) {
      this.update(props)
    }
  }

  @action
  update = (props: Partial<AccountRequest>) => {
    Object.assign(this, props)
  }

  fetch = async (account_id?: string) => {
    let { data } = await accountServer.get(account_id || this.id)
    // 通过试用申请的个人用户
    if (env.isPersonal) {
      const { data } = await Http.get('/user/freeTrail/remainingCoreTime')
      runInAction(() => {
        this.update({
          free_trail_balance: data
        })
      })
    }

    runInAction(() => {
      this.update(data.account)
    })
  }
}
