/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

/**
 * Fetch 模块用于处理原生 http 请求
 * 注：Http 模块用于处理经过 apiFramework format 的请求
 */

import axios from 'axios'
import qs from 'qs'
import { box } from '@/domain'
import AxiosInstance from '@/utils/HttpClient/IAxiosInstance'
import { message } from 'antd'
import { cloudshell } from './cloudshell'

const ShellHttp: AxiosInstance = <any>axios.create({
  timeout: 600000,
  withCredentials: true,
  paramsSerializer: function (params) {
    const o = Object.keys(params).reduce((obj, key) => {
      const item = params[key]
      if (Object.prototype.toString.call(item) === '[object Object]') {
        obj[key] = JSON.stringify(item)
      } else {
        obj[key] = item
      }
      return obj
    }, {})

    return qs.stringify(o)
  }
})

ShellHttp.interceptors.request.use(config => {
  config.baseURL = `${cloudshell.url}/service/cs-edge-portal`
  // 用box的token
  config.headers['x-box-fe-token'] = box.token
  return config
})

ShellHttp.interceptors.response.use(response => {
  const {
    data: { code, ...data }
  } = response

  if (!data.success) {
    const { disableErrorMessage, formatErrorMessage } = response.config

    if (!disableErrorMessage) {
      let msg
      msg = data.message

      if (formatErrorMessage) {
        msg = formatErrorMessage(msg)
      }
      msg && message.error(msg)
    }

    return Promise.reject({ response })
  }
  return response.data
})

export default ShellHttp
