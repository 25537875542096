/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observable, action, runInAction } from 'mobx'

import { Message } from './Message'
import { Http } from '@/utils'
import { PageCtx } from '../common'

export class Messages {
  @observable list: Message[] = []
  @observable page_ctx: PageCtx = new PageCtx()
  @observable unreadCount = 0
  @action
  updateList = list => {
    this.list = [...list]
  }
  @action
  updateUnreadCount = count => {
    this.unreadCount = count
  }

  find = id => this.list.find(item => item.message_id === id)

  fetchUnreadCount = () =>
    Http.get('/message/list', {
      params: {
        is_read: 1,
        page_index: 1,
        page_size: 1,
      },
    }).then(res => {
      let { page_ctx } = (res as any).data
      page_ctx = page_ctx || { total: 0 }
      this.updateUnreadCount(page_ctx.total)
      return res
    })

  fetch = (params: { type?; is_read?; page_index; page_size }) => {
    return Http.get('/message/list', {
      params: {
        ...params,
      },
    }).then(({ data: { list, page_ctx } }) => {
      this.updateList(list.map(item => new Message(item)))
      this.page_ctx.update(page_ctx)
    })
  }

  fetchLast = () => {
    this.fetchUnreadCount()
    return this.fetch({
      page_index: 1,
      page_size: 5,
    })
  }

  read = async (ids: string[]) => {
    await Http.put('/message/read', {
      message_id: ids,
    })

    runInAction(() => {
      ids.forEach(id => {
        const item = this.find(id)
        if (item) {
          item.is_read = true
        }
      })
    })
  }

  readAll = async () => {
    await Http.put('/message/read_all')

    runInAction(() => {
      this.list.forEach(item => {
        item.is_read = true
      })
    })
  }
}
