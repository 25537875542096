/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { action, observable } from 'mobx'
import { Http } from '@/utils'
import { ISection } from './Section'
import AppParam from './AppParam'
import { scList } from '@/domain'

class RemainingResource {
  @observable sc_id: string
  @observable cores: string
  constructor(data) {
    Object.assign(this, data)
  }
}
export class AppProps {
  @observable id: string
  @observable name: string
  @observable icon: string
  @observable type: string
  @observable version: string
  @observable app_params: AppParam[]
  @observable app_params_version: number
  @observable ttl: number
  @observable is_internal: boolean
  @observable description: string
  @observable sc_ids: string[]
  @observable cores: number[]
  @observable is_trial: boolean
  @observable remainingResource: RemainingResource[]
}

export default class App extends AppProps {
  constructor(data: AppProps) {
    super()
    Object.assign(this, data)
  }

  async getParams() {
    if (!this.app_params) {
      const res = await Http.get(`/application/${this.id}`)
      const params = []
      res.data.app_params?.sections.forEach((section: ISection) => {
        section.fields.forEach(field => {
          if (!field.hidden) {
            params.push(new AppParam(field))
          }
        })
      })
      this.app_params = params
    }
    return this.app_params
  }

  @action
  setScIds(scIds) {
    this.sc_ids = scIds
  }

  async getRuntimeScsCoresList() {
    const { data: scs_cores_list } = await Http.get(
      `/application/cores/${this.id}`
    )
    return scs_cores_list
  }

  async getRemainingResource(sc_ids: string[]) {
    const { data } = await Http.post('/application/remainingResource', {
      app_type: this.type,
      sc_ids
    })
    this.remainingResource = data
      .map(item => new RemainingResource(item))
      .filter(item => scList.list.some(sc => sc.sc_id === item.sc_id))
  }
}
