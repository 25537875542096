/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observable, action } from 'mobx'
import { Timestamp } from '@/domain/common'

enum JobState {
  // 未知
  JOB_UNKNOWN,
  // 排队中
  JOB_QUEUING,
  // 运行中
  JOB_RUNNING,
  // 完成
  JOB_SUCCESS,
  // 取消
  JOB_CANCELED,
  // 失败
  JOB_FAILED,
}

const mapJobStateToText = (state: JobState) => {
  const map = {
    1: '排队中',
    2: '运行中',
    3: '完成',
    4: '取消',
    5: '出错',
    7: '排队中',
  }
  return map[state]
}

class BaseJob {
  @observable id: string
  @observable user_id: string
  @observable sc_id: string
  @observable company_id: string
  @observable name: string
  @observable product_id: string
  @observable account_id: string
  @observable state: JobState
  @observable is_billed: boolean
  @observable billing_id: string
  @observable merchandise_id: string
  @observable sc_user_id: string
  @observable sc_job_id: string
  @observable cpu_num: number
  @observable node_num: number
  @observable cpu_time: number
  @observable sc_state: string

  @observable start_time: Timestamp
  @observable end_time: Timestamp
  @observable create_time: Timestamp
  @observable update_time: Timestamp
}

export type JobRequest = Omit<
  BaseJob,
  'start_time' | 'end_time' | 'create_time' | 'update_time'
> & {
  start_time: {
    seconds: number
    nanos: number
  }
  end_time: {
    seconds: number
    nanos: number
  }
  create_time: {
    seconds: number
    nanos: number
  }
  update_time: {
    seconds: number
    nanos: number
  }
}

export class Job extends BaseJob {
  constructor(props: Partial<BaseJob>) {
    super()
    this.update(props)
  }

  @action
  update({
    start_time,
    end_time,
    create_time,
    update_time,
    ...props
  }: Partial<BaseJob>) {
    Object.assign(this, props)
    start_time && (this.start_time = new Timestamp(start_time))
    end_time && (this.end_time = new Timestamp(end_time))
    create_time && (this.create_time = new Timestamp(create_time))
    update_time && (this.update_time = new Timestamp(update_time))
  }

  get displayCoreCount() {
    return this.cpu_num ? `${this.cpu_num} 核` : '--'
  }

  get displayState() {
    return mapJobStateToText(this.state)
  }

  get displayRunTime() {
    const runtimeInSeconds = this.cpu_time
    const hour = Math.floor(runtimeInSeconds / 3600)
      .toString()
      .padStart(2, '0')
    const minute = Math.floor((runtimeInSeconds % 3600) / 60)
      .toString()
      .padStart(2, '0')
    const second = (runtimeInSeconds % 60).toString().padStart(2, '0')
    return `${hour} 小时 ${minute} 分钟 ${second} 秒`
  }
}
