/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { env, NewBoxHttp } from '@/domain'

export default class NewResult {
  async linkToCommon(pairs: Record<string, string>, zoneId) {
    const res = await NewBoxHttp(zoneId).post('/filemanager/common/link', {
      link_pairs: pairs,
      project_id: env.project?.id,
      direction: 'from',
      bucket: 'result',
    })
    return res.data.infos
  }
}
