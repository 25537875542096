/* Copyright (C) 2016-present, Yuansuan.cn */

import { Http } from '@/utils'

export const softwareFreeTrialServer = {
  getApplyStatus() {
    return Http.get('/user/freeTrial/applyStatus')
  },
  apply(softwareWanted: string[], applySource: string) {
    return Http.post('/user/freeTrial/apply', {
      softwareWanted,
      applySource
    })
  },
  abandon() {
    return Http.post('/user/freeTrial/abandon')
  }
}
