/* Copyright (C) 2016-present, Yuansuan.cn */

import { Http, RawHttp } from '@/utils'

export type FilterParams = Partial<{
  user_id: string
  user_name: string
  merchandise_id: string
  month: string
  months: string[]
  out_resource_type: number
  project_id: string
  department_id: string
  types: Number[]
}>

export const billServer = {
  getList: (
    params: FilterParams & {
      page_index: number
      page_size: number
    }
  ) => {
    return Http.get('/bill', {
      params
    })
  },
  export: (params: FilterParams) => {
    const { months, types } = params

    delete params.months
    delete params.types

    let _types = types.reduce((pre, curr, index) => {
      pre[`types[${index}]`] = curr
      return pre
    }, {})

    return RawHttp.get('/bill/export', {
      params: {
        ...params,
        'months[0]': months[0],
        'months[1]': months[1],
        ..._types
      },
      responseType: 'blob'
    })
  }
}
