/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observable, action, runInAction } from 'mobx'
import { etcdServer } from '@/server'

export class BaseFeatures {
  @observable features: string[] = []
}

/**
 * EtcdFeatures 模块通过 etcd 配置不同运行环境下的特性
 */
export class EtcdFeatures extends BaseFeatures {
  constructor(props?: Partial<BaseFeatures>) {
    super()

    if (props) {
      this.update(props)
    }
  }

  @action
  update = (props: Partial<BaseFeatures>) => {
    Object.assign(this, props)
  }

  fetch = async () => {
    const { data: features } = await etcdServer.getFeatures()

    runInAction(() => {
      this.update({
        features
      })
    })
  }

  check = (feature: string) => {
    return this.features.includes(feature)
  }
}
