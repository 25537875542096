/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { message } from 'antd'
import {
  createHttp,
  interceptError,
  interceptResponse
} from '@ys/utils/HttpClient'
import qs from 'qs'
import { Modal } from '@ys/components'
import { single } from '@ys/utils'
import { env } from '@/domain'

const setHeader = (config, key: string, value: string) =>
  value && (config.headers[key] = value)

function interceptRequest(Http) {
  Http.interceptors.request.use(config => {
    setHeader(config, 'X-Project-Id', env?.project?.id)
    setHeader(config, 'X-Company-Id', env?.company?.id)
    setHeader(config, 'X-Account-Id', env?.accountId)

    return config
  })
}

export const RawHttp = createHttp()
interceptRequest(RawHttp)

export const Http = createHttp({
  paramsSerializer: function (params) {
    return qs.stringify(params)
  }
})

interceptRequest(Http)
interceptError(Http)
interceptResponse(Http)
Http.interceptors.response.use(
  response => {
    const { data } = response
    if (!data.success) {
      const { disableErrorMessage, formatErrorMessage } = response.config

      // not in project
      if (data.code === 1001 || data.errorCode === 1001) {
        single('in-project-modal', () =>
          Modal.showConfirm({
            title: '工作空间不存在',
            content: '您已退出当前工作空间',
            closable: false,
            CancelButton: null
          }).then(async () => {
            location.replace('/')
          })
        )
      } else if (data.code === 120006 || data.errorCode === 120006) {
        single('user-not-exist-modal', () =>
          Modal.showConfirm({
            title: '消息提示',
            content: '账号异常，点击确认重新登录',
            closable: false,
            CancelButton: null
          }).then(() => {
            env.logout()
          })
        )
      }
      let msg
      if (!disableErrorMessage) {
        msg = data.message
        if (formatErrorMessage) {
          msg = formatErrorMessage(msg)
        }
        msg && message.error(msg)
      }

      return Promise.reject({
        ...data,
        message: msg
      })
    }
    return data
  },
  error => {
    const { response } = error
    if (response) {
      switch (response.status) {
        case 409: {
          return single('login-conflict-modal', () =>
            Modal.showConfirm({
              cancelButtonProps: { style: { display: 'none' } },
              closable: false,
              content: '用户登录冲突，请重新登录'
            }).then(() => {
              env.logout()
            })
          )
        }
        case 401:
          window.location.replace(`/api/sso/login${window.location.search}`)
          break
        default:
          break
      }
    }

    return Promise.reject(error)
  }
)
