/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import styled from 'styled-components'

export const Style = styled.div`
  textarea {
    resize: none;
  }
`
