/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { FileServer } from '.'
import { box, env } from '@/domain'
import { cloudshell } from '@/domain/Shell'
import qs from 'qs'
import ShellHttp from '@/domain/Shell/ShellHttp'
import { UPLOAD_CHUNK_SIZE } from '@/constant'
import { formatPath } from '@/utils'

type DownloadParams = {
  paths: string[]
  token: string
}

export const terminalServer: FileServer = {
  delete: () => {
    return Promise.resolve()
  },

  list: async ({
    path = '.',
    folder_only = false,
  }: {
    path?: string
    folder_only?: boolean
  }) => {
    return ShellHttp.get('/ls', {
      params: {
        path: formatPath(path),
        company_id: env.company?.id,
        folder_only,
      },
    }).then(res => {
      res.data.infos = (res.data.infos || []).sort((a, z) => {
        const aTime = new Date(a.mod_time).getTime()
        const zTime = new Date(z.mod_time).getTime()

        return zTime - aTime
      })
      return res
    })
  },

  move: async () => {
    return Promise.resolve()
  },

  mkdir: async () => {
    return Promise.resolve()
  },

  getContent: async () => {
    return Promise.resolve()
  },

  stat: ({ ...params }: { path: string }) => {
    return ShellHttp.get('/stat', {
      params: {
        company_id: env.company?.id,
        ...params,
      },
    })
  },

  getFileUrl: async ({ paths }: DownloadParams) => {
    return `${cloudshell.url}/service/cs-edge-portal/download?${qs.stringify({
      token: box.token,
      company_id: env.company?.id,
      path: paths[0],
    })}`
  },

  download: async (params: DownloadParams) => {
    const aEl = document.createElement('a')
    aEl.href = await terminalServer.getFileUrl(params)
    document.body.appendChild(aEl)
    aEl.click()
    document.body.removeChild(aEl)
  },

  upload: async ({ file, path }: { file: File; path: string }) => {
    path = formatPath(path)

    await ShellHttp.post(
      `/pre-upload?${qs.stringify({
        path,
        file_size: file.size,
        company_id: env.company?.id,
      })}`,
      undefined,
      { formatErrorMessage: () => '权限不足' }
    )

    async function uploadChunk(index) {
      const finish = UPLOAD_CHUNK_SIZE * (index + 1) > file.size
      const query = {
        path,
        file_size: file.size,
        offset: UPLOAD_CHUNK_SIZE * index,
        slice_size: finish
          ? file.size - UPLOAD_CHUNK_SIZE * index
          : UPLOAD_CHUNK_SIZE,
        finish,
        company_id: env.company?.id,
      }
      const formData = new FormData()
      formData.append(
        'slice',
        file.slice(UPLOAD_CHUNK_SIZE * index, UPLOAD_CHUNK_SIZE * (index + 1))
      )
      await ShellHttp.post(`/upload?${qs.stringify(query)}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      if (!finish) {
        await uploadChunk(index + 1)
      }
    }

    await uploadChunk(0)
  },
}
