/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { Modal } from '@ys/components'
import { ApplyForm } from './ApplyForm'
import { notification } from 'antd'

export const apply = async () => {
  notification.close('cloudShellApplyError')
  await Modal.show({
    title: '申请',
    content: ({ onCancel, onOk }) => (
      <ApplyForm onCancel={onCancel} onOk={onOk} />
    ),
    footer: null,
  })
}
