/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { env, NewBoxHttp } from '@/domain'

export enum DraftType {
  Default = '',
  Vis = 'vis',
  Redeploy = 'redeploy',
  Continuous = 'continuous'
}

export default class Draft {
  type: DraftType
  zoneId: string
  constructor(type: DraftType = DraftType.Default, zoneId: string) {
    this.type = type
    this.zoneId = zoneId
  }

  async deleteFile(path: string) {
    return await NewBoxHttp(this.zoneId).delete('/filemanager/rm', {
      params: {
        bucket: 'draft',
        path,
        project_id: env.project?.id,
        bucket_keys: { draft_type: this.type }
      }
    })
  }

  async deleteFiles(paths: string[]) {
    return await Promise.all(paths.map(path => this.deleteFile(path)))
  }

  async listFile(recursion: boolean = true) {
    try {
      const res = await NewBoxHttp(this.zoneId).get('/filemanager/ls', {
        params: {
          bucket: 'draft',
          path: '.',
          project_id: env.project?.id,
          recursion,
          bucket_keys: { draft_type: this.type }
        }
      })
      return res.data ? res.data.infos : []
    } catch (e) {
      return []
    }
  }

  async getFileContent(path: string) {
    try {
      const res = await NewBoxHttp(this.zoneId).get('/filemanager/cat', {
        params: {
          bucket: 'draft',
          path: window.encodeURIComponent(path),
          offset: 0,
          length: 10000,
          project_id: env.project?.id,
          bucket_keys: { draft_type: this.type }
        }
      })
      return res.data ? res.data.content : ''
    } catch (e) {
      return ''
    }
  }

  moveFile(srcPath = '', destPath = '') {
    return NewBoxHttp(this.zoneId).post('/filemanager/mv', {
      bucket: 'draft',
      project_id: env.project?.id,
      src_path: srcPath,
      dest_path: destPath || '.',
      bucket_keys: { draft_type: this.type }
    })
  }

  mkdir(path = '') {
    return NewBoxHttp(this.zoneId).post('/filemanager/mkdir', {
      bucket: 'draft',
      project_id: env.project?.id,
      path,
      bucket_keys: { draft_type: this.type }
    })
  }

  clean() {
    return NewBoxHttp(this.zoneId).post('/filemanager/draft/clean', {
      project_id: env.project?.id,
      bucket_keys: { draft_type: this.type }
    })
  }

  submit() {
    return NewBoxHttp(this.zoneId).post('/filemanager/draft/submit_to_input', {
      project_id: env.project?.id,
      bucket_keys: { draft_type: this.type }
    })
  }

  back(
    id: string,
    config?: {
      disableErrorMessage?: boolean
      formatErrorMessage?: () => string
    }
  ) {
    return NewBoxHttp(this.zoneId).post(
      '/filemanager/draft/input_back_to_draft',
      {
        input_folder_uuid: id,
        project_id: env.project?.id,
        bucket_keys: { draft_type: this.type }
      },
      config
    )
  }

  async putResultToDraft(
    project_id,
    job_id,
    file_list?,
    config?: {
      disableErrorMessage?: boolean
      formatErrorMessage?: () => string
    }
  ) {
    return await NewBoxHttp(this.zoneId).post(
      '/filemanager/draft/result_back_to_draft',
      {
        job_id,
        project_id: project_id || env.project?.id,
        bucket_keys: { draft_type: this.type },
        file_list
      },
      config
    )
  }

  async linkFromCommon(pairs: Record<string, string>) {
    const res = await NewBoxHttp(this.zoneId).post('/filemanager/common/link', {
      link_pairs: pairs,
      project_id: env.project?.id,
      direction: 'to',
      bucket: 'draft',
      bucket_keys: { draft_type: this.type }
    })
    return res.data.infos
  }

  async upload({ filename, file, data }) {
    // 构造要上传的数据
    const formData = new FormData()
    formData.append('file', file)
    formData.append('filename', filename)
    Object.keys(data).forEach(key => formData.append(key, data[key]))
    await NewBoxHttp(this.zoneId).post('/filemanager/upload', formData)
  }
}
