/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useState } from 'react'
import { AutoComplete } from 'antd'

const { Option } = AutoComplete

interface EmailInput {
  onChange: (v: string) => void
  initialValue: string
}

export const EmailInput = ({ onChange, initialValue }: EmailInput) => {
  const [result, setResult] = useState<string[]>([])
  const handleSearch = (value: string) => {
    let res: string[]
    if (!value || value.indexOf('@') >= 0) {
      res = []
    } else {
      res = ['163.com', 'qq.com', 'gmail.com', 'outlook.com'].map(
        domain => `${value}@${domain}`
      )
    }
    setResult(res)
  }
  return (
    <AutoComplete
      onSearch={handleSearch}
      placeholder='请输入邮件地址'
      defaultValue={initialValue}
      onChange={onChange}>
      {result.map((email: string) => (
        <Option key={email} value={email}>
          {email}
        </Option>
      ))}
    </AutoComplete>
  )
}
