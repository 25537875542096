export const industryOptions = [
  '汽车及零配件',
  '电子电器',
  '机械制造',
  '科研院所',
  '能源电力',
  '通信工程',
  '船舶海通',
  '其他'
]

export const softwareOptions = [
  ['Abaqus', 'BLASTN', 'BLASTP', 'BLASTX'],
  ['CFX', 'Code Saturne', 'COMSOL', 'Feko'],
  ['Fluent', 'HFSS', 'LS-DYNA', 'Maxwell'],
  ['Mechanicl', 'Nastran', 'OptiStruct', 'STAR-CCM'],
  ['SW4', 'TRUST4', '其他软件']
]

export const problemOptions = ['算力不足', '软件license不足']
