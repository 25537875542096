/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { showMask } from '@/components'
import { Icon } from '@ys/components'
import { softwareFreeTrialServer } from '@/server'
import { env, softwareFreeTrial } from '@/domain'
import { message, Checkbox, Row, Col } from 'antd'
const CheckboxGroup = Checkbox.Group

const plainOptions = [
  'Actran',
  'Adams',
  'Cradle CFD',
  'Dytran',
  'Easy5',
  'Marc',
  'Nastran',
  'Patran',
  'ScFlow',
  'ScStream'
]

const StyledLayout = styled.div`
  width: 560px;
  height: 440px;
  background-color: white;
  position: relative;

  > .banner {
    width: 223px;
    height: 58px;
    background-image: linear-gradient(90deg, #5e70ff 0%, #0082f1 100%);
    font-size: 28px;
    color: #ffffff;
    letter-spacing: 1.17px;
    font-weight: 600;
    border-radius: 2px;
    position: absolute;
    top: -20px;
    left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  > .header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;

    > .close {
      position: absolute;
      color: white;
      right: 20px;
      top: 20px;
      font-size: 24px;
    }

    > img {
      position: absolute;

      &.bg {
        width: 561px;
      }

      &.text {
        left: 40px;
        top: 76px;
      }
    }
  }

  > main {
    padding-top: 200px;
    padding-left: 40px;
    font-size: 16px;
    color: #333333;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 21px;
    font-family: PingFangSC-Regular;
    color: #333333;

    > .tip {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
      letter-spacing: 0.5px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 8px;
      span {
        padding-right: 5px;
        color: #d0021b;
      }
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .btn {
      width: 320px;
      height: 60px;
      background: #3182ff;
      border-radius: 33px;
      color: white;
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    p.warning {
      font-size: 14px;
      line-height: 20px;
      color: #f5222d;
      font-weight: 400;
      margin-top: 8px;
    }
  }
`

type Props = {
  onCancel: () => void | Promise<void>
  onOk: (selectedKeys: string[]) => void | Promise<void>
}

const Trial = observer(function Trial(props: Props) {
  const state = useLocalStore(() => ({
    selectedKeys: [],
    loading: false,
    showError: false,
    update(
      props: Partial<{
        selectedKeys: string[]
        loading: boolean
        showError: boolean
      }>
    ) {
      Object.assign(this, props)
    },
    get indeterminate() {
      const length = this.selectedKeys.length
      return length > 0 && length < plainOptions.length
    },
    get allChecked() {
      return this.selectedKeys.length === plainOptions.length
    }
  }))

  function onChange(list) {
    state.update({
      selectedKeys: list
    })
  }

  function onCheckAllChange(e) {
    state.update({
      selectedKeys: state.allChecked ? [] : plainOptions
    })
  }

  async function onConfirm() {
    if (state.loading) {
      return
    }
    try {
      state.update({
        loading: true
      })

      if (state.selectedKeys.length === 0) {
        state.update({
          showError: true
        })
      } else {
        state.update({
          showError: false
        })
        await props.onOk(state.selectedKeys)
      }
    } finally {
      state.update({
        loading: false
      })
    }
  }

  async function onCancel() {
    if (state.loading) {
      return
    }
    try {
      state.update({
        loading: true
      })
      await props.onCancel()
    } finally {
      state.update({
        loading: false
      })
    }
  }

  return (
    <StyledLayout>
      <div className='banner'>限时免费试用</div>
      <div className='header'>
        <img className='bg' src={require('./bg.svg')} />
        <img className='text' src={require('./text.svg')} />
        <Icon className='close' type='cancel' onClick={onCancel} />
      </div>
      <main>
        <div className='tip'>
          <span>*</span>
          试用软件选择：
        </div>
        <Checkbox
          indeterminate={state.indeterminate}
          onChange={onCheckAllChange}
          checked={state.allChecked}>
          全部
        </Checkbox>
        <CheckboxGroup value={state.selectedKeys} onChange={onChange}>
          <Row>
            {plainOptions.map(option => (
              <Col span={6} key={option}>
                <Checkbox value={option}>{option}</Checkbox>
              </Col>
            ))}
          </Row>
        </CheckboxGroup>
      </main>
      <footer>
        <div className='btn' onClick={onConfirm}>
          申请试用
        </div>
        {state.showError && <p className='warning'>请选择至少一款试用软件</p>}
      </footer>
    </StyledLayout>
  )
})

export const showTrial = async (applySource = 'system') => {
  // 智算未来不支持使用申请
  // 仅限个人用户可以申请试用MSC
  if (!env.shouldShowTrialModal) {
    return
  }

  // 如果已经有申请记录，则不弹出系统推荐
  if (applySource === 'system') {
    await softwareFreeTrial.fetch()
    if (softwareFreeTrial.status !== 0) {
      return
    }
  }

  showMask({
    content: ({ onClose }) => (
      <Trial
        onCancel={async () => {
          if (applySource === 'system') {
            await softwareFreeTrialServer.abandon()
          }
          onClose()
        }}
        onOk={async (softwareWanted: string[]) => {
          await softwareFreeTrialServer.apply(softwareWanted, applySource)
          message.success('申请成功')
          onClose()
        }}
      />
    ),
    closable: false
  })
}
