/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observable, action, runInAction } from 'mobx'
import { Http } from '@/utils'
import { Uploader } from '@ys/components'
import { CloudShellPermissionEnum } from '@/constant'
class BaseStore {
  @observable url: string
  @observable permission: CloudShellPermissionEnum
}

export class Cloudshell extends BaseStore {
  constructor() {
    super()
  }

  @action
  async init() {
    await Promise.all([this.fetch(), this.setBaseUrl()])
  }

  @action
  async fetch() {
    const { data } = await Http.get('/cloudshell/apply/status')
    runInAction(() => {
      this.updateData({
        permission: data?.status
      })
    })
  }

  async setBaseUrl() {
    const {
      data: { base_url }
    } = await Http.get('/cloudshell/baseurl')

    runInAction(() => {
      this.updateData({
        url: base_url
      })
    })
  }

  @action
  updateData(data: Partial<BaseStore>) {
    Object.assign(this, data)
  }
}

export const cloudshell = new Cloudshell()
export const cloudshellUploader = new Uploader({ preUploadUrl: '/pre-upload' })
