/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Button, Modal } from '@ys/components'

const correctPng = require('@/assets/images/correct.png')
const StyledLayout = styled.div<{ correct: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  > .correct {
    width: 106px;
    height: 106px;
    background: url(${props => props.correct});
    background-size: 100% 100%;
    padding: 40px 0 20px 0;
  }
  > .succeed {
    font-size: 16px;
    color: #333333;
    padding: 20px 0;
  }
  > .info {
    font-size: 14px;
    color: #999999;
    padding: 0 0 10px 0;
  }
  > .button {
    margin: 20px 0 0 0;
  }
`

interface Props {
  onOk: () => void
}

export const SucceedModal = observer(function succeedModal({ onOk }: Props) {
  const state = useLocalStore(() => ({
    countDown: null,
    count: 3
  }))

  const fetchCount = () => {
    const timer = setInterval(() => {
      if (state.count >= 0) {
        state.count--
      } else {
        clearInterval(timer)
      }
    }, 1000)
  }

  useEffect(() => {
    fetchCount()
  }, [])

  useEffect(() => {
    if (state.count < 0) {
      onOk()
    }
  }, [state.count])
  return (
    <StyledLayout correct={correctPng}>
      <div className='correct'></div>
      <div className='succeed'>提交成功</div>
      <div className='info'>您已成功提交企业“远算科技”的升级申请</div>
      <div className='info'>请您耐心等待~</div>
      {state.count > 0 ? (
        <Button
          type='primary'
          onClick={() => onOk()}
          className='button'>{`我知道了（${state.count}s）`}</Button>
      ) : (
        <Button className='button' type='primary' onClick={() => onOk()}>
          我知道了
        </Button>
      )}
    </StyledLayout>
  )
})

export async function CompanyApplySuccess() {
  return await Modal.show({
    showHeader: false,
    bodyStyle: { height: '400px' },
    width: 560,
    content: ({ onOk }) => <SucceedModal onOk={onOk} />,
    footer: null
  })
}
