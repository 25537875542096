/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { observable, action, computed } from 'mobx'

export class BaseUser {
  @observable id: string
  @observable name: string
  @observable account_id: string
  @observable email: string
  @observable display_user_name: string
  @observable user_name: string
  @observable real_name: string
  @observable public_box_domain: string
  @observable phone: string
  @observable headimg_url: string
  @observable wechat_union_id: string
  @observable wechat_nick_name: string
  @observable wechat_open_id: string
  @observable wechat_nickname: string
  @observable department_id: string
  @observable max_projects: number
}

type UserRequest = Omit<BaseUser, 'id'> & {
  ysid: string
}

export class User extends BaseUser {
  constructor(props?: Partial<UserRequest>) {
    super()

    if (props) {
      this.update(props)
    }
  }

  @computed
  get displayName() {
    return this.real_name || this.display_user_name || this.phone
  }

  @action
  update({ ysid, ...props }: Partial<UserRequest>) {
    Object.assign(this, props)

    if (ysid) {
      this.id = ysid
    }
  }
}
