/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Form } from 'antd'
import { problemOptions } from './const'

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > .row {
    display: flex;
    width: 100%;
    padding: 5px 0;
    > .box {
      margin-right: 18px;
      width: 104px;
      height: 32px;
      border: 1px solid #dddddd;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      border-radius: 2px;
    }
    > .box:hover {
      border: 1px solid #005dfc;
    }
    > .checked {
      background: #3182ff;
      border-radius: 2px;
      color: #ffffff;
      border: none;
      border: 1px solid transparent;
    }
  }
`

interface Props {
  value?: string[]
  onChange?: (value: string[]) => void
  dataOptions: any
}

export const Group = observer(function Group({
  value,
  dataOptions,
  onChange
}: Props) {
  const state = useLocalStore(() => ({
    checked: [false, false, false, false],
    setChecked(index) {
      this.checked[index] = !this.checked[index]
      const checkedData = dataOptions.filter(
        (item, index) => this.checked[index]
      )
      onChange(checkedData)
    },
    setDefaultChecked() {
      value?.map(item => {
        dataOptions.map((ittem, index) => {
          if (ittem === item) {
            this.checked[index] = !this.checked[index]
          }
        })
      })
    }
  }))

  useEffect(() => {
    state.setDefaultChecked()
  }, [])
  return (
    <div className='row'>
      {dataOptions?.map((item, index) => {
        return (
          <div
            onClick={e => state.setChecked(index)}
            className={state.checked[index] ? 'checked box' : 'box'}
            key={index}>
            {item}
          </div>
        )
      })}
    </div>
  )
})

export const CheckBox = observer(function checkBox({
  value,
  onChange,
  dataOptions
}: Props) {
  const state = useLocalStore(() => ({
    software: dataOptions,
    checkedSoft: value || [],
    setSoftware(item) {
      if (state?.checkedSoft?.indexOf(item) === -1) {
        this.checkedSoft.push(item)
      } else {
        this.checkedSoft = this.checkedSoft.filter(e => e !== item)
      }
      onChange(this.checkedSoft)
    }
  }))

  return (
    <StyledLayout>
      {state.software?.map((data, ind) => {
        return (
          <div className='row' key={ind}>
            {data?.map((item, index) => {
              return (
                <div
                  onClick={e => {
                    state.setSoftware(item)
                  }}
                  className={
                    state?.checkedSoft?.indexOf(item) !== -1
                      ? 'checked box'
                      : ' box'
                  }
                  key={index}>
                  {item}
                </div>
              )
            })}
          </div>
        )
      })}
    </StyledLayout>
  )
})

const PageLayout = styled.div`
  display: flex;
  flex-direction: row;
  > .name {
    font-size: 16px;
    color: #666666;
    line-height: 32px;
  }
  .row {
    display: flex;
    width: 100%;
    padding: 5px 0;
    > .box {
      margin: 0 10px;
      width: 104px;
      height: 22px;
      border: 1px solid #666666;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
    }
    > .box:hover {
      border: 1px solid #005dfc;
    }
    > .checked {
      background: #3182ff;
      border-radius: 2px;
      color: #ffffff;
      border: none;
      border: 1px solid transparent;
    }
  }
`

export const CheckForm = observer(function checkForm() {
  return (
    <PageLayout>
      <div className='name'>常见问题：</div>
      <Form.Item style={{ height: '28px' }} name='problem'>
        <Group dataOptions={problemOptions} />
      </Form.Item>
    </PageLayout>
  )
})
