/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import styled from 'styled-components'

export const Wrapper = styled.div``

export const StyledTable = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  th,
  td {
    border-bottom: 1px solid #f0f0f0;
    padding-left: 20px;
  }

  thead {
    th {
      height: 54px;
      text-align: left;
      background: #f3f5f8;
      font-size: 14px;
      color: #252525;
    }
  }

  tbody {
    height: calc(100% - 54px);
    overflow: auto;

    td {
      height: 50px;
    }
  }

  tbody > tr:hover > td {
    background: #f6f8fa;
  }

  th.padding,
  td.padding {
    padding: 0;
  }
`

export const LeftPadding = styled.td<{ level: number; indent?: number }>`
  width: ${props => props.level * (props.indent || 24) + 'px'};
  padding: 0 !important;
`
