/* eslint-disable */
export const CUSTOM_CONFIG = {
  id: '',
  title: '',
  favicon: ''
  /*
  id: "cdcs",
  logo: "cdcs-logo.png",
  logoSmall: "cdcs-logo-small.png",
  favicon: "cdcs-favicon.png",
  title: "成都泛超算云平台",
*/
  /*
  id: "t3",
  logo: "t3-logo.svg",
  logoSmall: "t3-logo-small.svg",
  favicon: "t3-logo-small.svg",
  title: "远算泛超算云中汽专属版",
*/
}

// set simplant flag
localStorage.setItem('simplant-version', 'v1')
