/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { Http } from '@/utils'
import { observable, action, runInAction } from 'mobx'
import { PageCtx } from '@/domain/common'
import { JobRequest, Job } from './Job'

type JobsRequest = {
  list: JobRequest[]
  page_ctx: {
    index
    size
    total
  }
}

class BaseJobList {
  @observable list: Job[]
  @observable pageCtx: PageCtx = new PageCtx()
}

export class JobList extends BaseJobList {
  @action
  update({ list, page_ctx }: JobsRequest) {
    this.list = list.map(item => new Job(item))
    this.pageCtx = new PageCtx(page_ctx)
  }

  @action
  async fetch() {
    const {
      data: { data: list, page_ctx },
    } = await Http.get('/cloudshell/jobs', {
      params: {
        page: {
          index: this.pageCtx.index,
          size: 8,
        },
      },
    })
    runInAction(() => {
      this.update({
        list,
        page_ctx,
      })
    })
  }
}

export const jobList = new JobList()
