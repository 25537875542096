/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

export const errorMapping = {
  110001: '参数错误',
  110002: '网络传输/磁盘io错误',
  110003: 'download token无效',
  110004: '打包错误',
  110005: '下载文件不存在',
  110006: 'jwttoken无效',
  110007: '移动文件错误',
  110008: '创建目录错误',
  110009: '不能计算相对路径',
  110010: '不能创建这样的文件/磁盘空间不足',
  110011: 'uploadid过期',
  110012: '用户没有权限访问该工作空间',
  130005: '文件/目录不存在',
  // 超算上暂时还未 pull 到刚创建的任务，忽略这一报错
  133102: ''
}
